import * as React from 'react';
import { Slot, Slottable } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from 'utils/shadcn';
import { ReloadIcon } from '@radix-ui/react-icons';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-sm text-sm font-medium transition-colors disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'bg-primary text-primary-foreground shadow hover:bg-primary/90',
        destructive:
          'bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90',
        outline:
          'border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground',
        secondary:
          'bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80',
        success:
          'bg-success text-success-foreground shadow-sm hover:bg-success/90',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        frost: 'bg-frost hover:bg-opacity-25 hover:text-accent-foreground',
        link: 'text-gold underline-offset-1 underline font-normal',
        action:
          'bg-orange-gradient relative after:ease overflow-hidden after:absolute after:left-0 after:top-0 after:h-full after:w-full after:scale-x-0 after:-translate-x-12 after:hover:scale-x-[200%] after:hover:opacity-0 after:skew-x-[-50deg] after:bg-white after:duration-0 hover:after:duration-500 after:z-50 after:transition-all  after:opacity-50 after:origin-top-left',
        'secondary-action':
          'bg-teal-gradient relative after:ease overflow-hidden after:absolute after:left-0 after:top-0 after:h-full after:w-full after:scale-x-0 after:-translate-x-12 after:hover:scale-x-[200%] after:hover:opacity-0 after:skew-x-[-50deg] after:bg-white after:duration-0 hover:after:duration-500 after:z-50 after:transition-all  after:opacity-50 after:origin-top-left',
      },
      size: {
        default: 'h-9 px-4 py-2',
        sm: 'h-8 rounded-sm px-3 text-xs',
        lg: 'h-10 rounded-sm px-4',
        xl: 'h-12 rounded-sm px-4',
        icon: 'h-9 w-9',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  isProcessing?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      children,
      isProcessing,
      disabled,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        disabled={isProcessing === true || disabled}
        {...props}
      >
        <span
          className={cn('h-4 transition-all', {
            'w-4 pr-8 opacity-100': isProcessing,
            'w-0 pr-0 opacity-0': !isProcessing,
          })}
        >
          <ReloadIcon className={isProcessing ? 'animate-spin' : undefined} />
        </span>

        <Slottable>{children}</Slottable>
      </Comp>
    );
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
